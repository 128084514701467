import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import _get from "lodash/get"

import { colors } from "css/theme"
import { h3 } from "css/primitives"
import { Form, Banner } from "src/views/resource/components"
import { FluidImage } from "src/components"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import {
  compileArticleSEOFields,
  computeImageAttr,
  replaceImageSEOBase,
} from "../util"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { ResourceType } from "../components/Labels"
import { DraftLabel } from "src/components"
import "src/queries/featured_media"

const wrapper = css`
  padding-bottom: 50px;
  padding-top: 176px;
  background-color: ${colors.background};
  background-image: linear-gradient(to bottom, #000000, #001f24 19%);
  margin-top: -90px;
  @media (max-width: 767px) {
    padding-top: 130px;
  }
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const inner = css`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
  }
`

const left = css`
  width: 50%;
  padding-right: 3rem;
  @media (max-width: 1199px) {
    padding-right: 0;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`

const right = css`
  width: 50%;
  padding-left: 3rem;
  @media (max-width: 1199px) {
    padding-left: 1.5rem;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`

const image = css`
  max-height: 570px;
  text-align: right;
  img {
    max-height: inherit;
  }
  @media (max-width: 991px) {
    text-align: left;
  }
`

const title = [
  h3,
  css`
    color: white;
    font-weight: bold;
    font-family: var(--heading);
    line-height: 1.64;
    margin-bottom: 3rem;
    margin-top: 1rem;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
    @media (max-width: 479px) {
      font-size: 1.5rem;
    }
  `,
]

const content = css`
  font-size: 18px;
  line-height: 1.56;
  opacity: 0.75;
  color: white;
  max-width: 480px;
  @media (max-width: 479px) {
    font-size: 18px;
  }
  // Other tags to code for
  p {
    margin-bottom: 38px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    font-family: var(--heading);
    letter-spacing: 0.78px;
    line-height: normal;
    color: ${colors.brightBlue};
  }

  a {
    color: ${colors.orange};
  }
  li {
    margin-bottom: 1rem;
  }
  blockquote {
    margin-left: 0;
    margin-right: 1.78rem;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 1.44625rem;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.78rem;
    line-height: 1.78;
    color: ${colors.blockquoteText};
    font-style: italic;
    border-left: 0.33375rem solid ${colors.blockquoteBorder};
  }
`

const banner = css`
  background-color: ${colors.background};
  padding-bottom: 100px;
`

const ResourceTemplate = ({ data }) => {
  const post = data.wordpressWpResources
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = compileArticleSEOFields(post, siteUrl)
  const [imageUrl, altText] = computeImageAttr(post)
  return (
    <PageLayout options={{ headingBackground: false }}>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        extra={otherMetaTags}
        description={post.excerpt}
      />

      <div css={wrapper}>
        <div css={inner}>
          <div css={left}>
            <div css={image}>
              <FluidImage fluid={imageUrl} alt={altText} />
            </div>
          </div>
          <div css={right}>
            <ResourceType type={post.acf.type} />
            <h1 css={title} dangerouslySetInnerHTML={{ __html: post.title }} />
            <DraftLabel
              status={post.status}
              style={{ marginTop: "-40px", marginBottom: "1rem" }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: post.content }}
              css={content}
            />
            <Form
              resourceLink={replaceImageSEOBase(
                _get(post, "acf.pdf_link.source_url", null)
              )}
              title={post.title}
            />
          </div>
        </div>
      </div>
      <div css={banner}>
        <Banner data={data} />
      </div>
    </PageLayout>
  )
}

export default ResourceTemplate

export const query = graphql`
  query ResourceTemplate($id: String!) {
    wordpressWpResources(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      status
      ...FeaturedMediaResources
      acf {
        pdf_link {
          source_url
        }
        type
      }
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
    costSectionImage: file(relativePath: { eq: "homepage/cost-image-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 530) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
